import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './containers/App/index';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { getStore } from './redux/store';
import { createBrowserHistory as createHistory } from 'history';
import { Router } from 'react-router-dom';
import { setMenu } from './containers/Header/actions';
import { mobilecheck, initViewPortWatcher } from './utilities/browser';
window.ReactHistory = createHistory();
const store = getStore({}, window.ReactHistory);

//changed form ConnectedRouter to Router fix bug history
ReactDOM.render(
  <Provider store={store}>
    <Router history={window.ReactHistory}> 
      <App />
    </Router>
  </Provider>
  , document.getElementById('root'));


window.ReactHistory.listen(location => {
  store.dispatch(setMenu('mainMenu', 0));
  store.dispatch(setMenu('notificationMenu', 0));
  document.body.classList.remove('main-menu-open');
});

if (mobilecheck()) {
  window.isMobileDevice = true;
}
initViewPortWatcher();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
