import React from 'react';
import Checkbox from 'rc-checkbox';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import './styles.scss';

const ListHeader = ({
  checkedAll,
  disabledCheckbox,
  onCheckAll,
  disabled,
  openSelectTemplateModal,
  onCheck,
  offerFilter,
  totalSelected = 0,
  filterOptions = []
}) => {
  if (filterOptions.length) {
    return (
      <div className="header-offer-filter-wrapper">
        {filterOptions.map((option) => {
          const checked = offerFilter.includes(option.value);
          return (
            <label key={option.value} className={`offer-badge ${checked && 'badge-active'}`}>
              <Checkbox {...option} checked={checked} onChange={onCheck} />
              {option.name}
            </label>
          );
        })}
      </div>
    );
  }

  return (
    <div className="header-student-checkbox-wrapper">
      <div className="header-student-checkbox">
        <label className="check-all">
          <Checkbox checked={checkedAll} disabled={disabledCheckbox} onChange={onCheckAll} />
          全ての学生を選択
        </label>
        <Button
          className="send-offer-request-btn"
          disabled={disabled}
          onClick={openSelectTemplateModal}
        >
          <img src="images/icon-review-offer-sent.svg" alt="meshi" />
            選択した学生にオファーを送信
         ({totalSelected})
        </Button>
      </div>
    </div>
  );
};

ListHeader.propTypes = {
  checkedAll: PropTypes.bool,
  disabledCheckbox: PropTypes.bool,
  onCheckAll: PropTypes.func,
  disabled: PropTypes.bool,
  openSelectTemplateModal: PropTypes.func,
  totalSelected: PropTypes.number,
  offerFilter: PropTypes.array,
  onCheck: PropTypes.func,
  filterOptions: PropTypes.array
};

export default ListHeader;
