import './styles.scss';
import React from 'react';
import { Grid, Row, Col, Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const AvailablePlan = ({ plans }) => {
  return (
    <div id="available-plans-container">
      <Grid>
        <div id="title-available-plan">
          <img src="images/graphics-1-icon-on-dashboard-sm-plan.svg" alt="meshi"/>
          オファープラン
        </div>
        <Row>
          {plans.map((item) => {
            const isAvailable =
              item.status === 'ACTIVE' &&
              item.remain > 0;
            return (
              isAvailable && (
                <Col
                  xs={12}
                  md={12}
                  key={`available-plan-${item._id}}`}
                  className="availabale-plan"
                >
                  <p>
                    <strong>{item.name}</strong>
                  </p>
                  <p>
                    <Glyphicon glyph="ok" />
                    {item.used}/{item.total}
                  </p>
                  <p>
                    {moment(item.startDate.iso).format('YYYY.MM.DD')}~
                    {moment(item.endDate.iso).format('YYYY.MM.DD')}
                  </p>
                </Col>
              )
            );
          })}
        </Row>
      </Grid>
    </div>
  );
};

AvailablePlan.propTypes = {
  plans: PropTypes.array
};

export default AvailablePlan;