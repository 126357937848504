import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ReuseEventModal from '../EventsManagement/ReuseEventModal';
import './style.scss';

const CreateEventButton = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        id="create-event-button"
        onClick={() => {
          setShow(true);
        }}
      >
        <div>
          <img src="images/add-event.svg" alt="Icon" />
        </div>
        <div>イベント作成</div>
      </div>
      <ReuseEventModal show={show} onHide={() => setShow(false)} />
    </>
  );
};
CreateEventButton.propTypes = {
  show: PropTypes.bool,
  closePopup: PropTypes.func
};

export default CreateEventButton;
