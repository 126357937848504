import React, { useState } from 'react';
import Checkbox from 'rc-checkbox';
import { Collapse } from 'react-bootstrap';
import 'rc-checkbox/assets/index.css';
import PropTypes from 'prop-types';
import _ from 'lodash'

const CollapsedGroup = ({ groupDetail, selectedState }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selected, setSelected] = selectedState;
  // eslint-disable-next-line
  const { list = [], nameList = [], name = '', _id = '' } = groupDetail;
  const onSelectGroup = () => {
    let newSelectedItems = selected.filter(selectedItem => selectedItem.objectId !== 'u000000196')
    list.forEach(value => {
      const selectedItem = _.find(newSelectedItems, { objectId: value.objectId} )
      if(!selectedItem){
        newSelectedItems.push(value)
      }
    })
    setSelected(newSelectedItems)
  };
  const onDeselectGroup = () => {
    let newSelectedItems = [...selected]
    list.forEach(value => {
      newSelectedItems  = newSelectedItems.filter(selectedItem => selectedItem.objectId !== value.objectId)
    })
    setSelected(newSelectedItems);
  };

  const onSelectItem = (value) => {
    if(value.objectId === 'u000000196'){
      setSelected([value])
    } else {
      let newSelectedItems = selected.filter(selectedItem => selectedItem.objectId !== 'u000000196')
      const selectedItem = _.find(newSelectedItems, { objectId: value.objectId} )
      if(!selectedItem){
        newSelectedItems.push(value)
        setSelected(newSelectedItems)
      }
    }
  };

  const onDeselectItem = (value) => {
    setSelected(selected.filter(selectedItem => selectedItem.objectId !== value.objectId));
  };

  const checkedGroupData = () => {
    let checkedGroup = true;
    list.forEach(item => {
      if(!_.find(selected, { objectId: item.objectId})){
        checkedGroup = false
      }
    })
    return checkedGroup
  }
  const checkedGroup = checkedGroupData()
  const selectedNames = selected.map(item => item.name)
  return (
    <div className="collapsed-group-container">
      <div className="collapsed-header">
        <img
          className={`collapsed-toggle ${isOpen && 'collapsed-open'}`}
          alt="dropdown-icon"
          src="/images/dropdown-orange.svg"
          onClick={() => setIsOpen(!isOpen)}
        />
        <label>
          { _id !== 'group_8' && <Checkbox 
            checked={checkedGroup}
            onChange={(e) => {
              if (e.target.checked) {
                onSelectGroup();
              } else {
                onDeselectGroup();
              }
            }} 
          />}
          {name}
        </label>
      </div>
      <Collapse in={isOpen} unmountOnExit>
        <div className="collapsed-body">
          {list.map((item) => {
            let checked = false;
            checked = selectedNames.includes(item.name);
            return (
              <div key={item.objectId}>
                <label>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onSelectItem(item);
                      } else {
                        onDeselectItem(item);
                      }
                    }}
                  />
                  {item.name}
                </label>
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

CollapsedGroup.propTypes = {
  groupDetail: PropTypes.object,
  selectedState: PropTypes.array
};

export default CollapsedGroup;