import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getPlans } from 'containers/Plans/actions'

class Plans extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getPlans()
  }

  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, {...this.props})}
      </React.Fragment>
    );
  }
}

Plans.propTypes = {
  history: PropTypes.any,
  plans: PropTypes.any,
  getPlans: PropTypes.func
};

const mapStateToProps = state => {
  return {
    plans: state.get('plans').get('list')
  };
};

const mapDispatchToProps = dispatch => ({
  getPlans: () => dispatch(getPlans())
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withImmutablePropsToJS
)(Plans);
