/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import {
  requestWaitingStudentOfferEvents,
  resetWaitingStudentOfferEventList
} from './actions';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {getSeoDataByCodeRequest} from '../Seo/actions';
import CollapseElement from './CollapseElement';
import { OFFER_EVENT_STATUS, OFFER_EVENT_STATUS_KEYS } from '../../utilities/constants';
import './styles.scss';
import { getOfferEventListRequest } from '../Management/StudentsManagement/EventList/actions';
import { getOfferEventsByStatusRequest } from '../EventHistory/actions';

const INIT_LIST = OFFER_EVENT_STATUS.map(item => { return { ...item, open: false }; });

class WaitingStudentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: INIT_LIST,
      openOfferCollapse: false
    };
  }

  componentWillUnmount() {
    if (this.props.page && this.props.history && this.props.history.action === 'POP') {
      return;
    }
    this.props.resetOfferEvents();
  }
  componentDidMount() {
    const { currentUser, getSeoDataByCode } = this.props;

    getSeoDataByCode();

    const isPresident = get(currentUser, 'role', '') === 'PRESIDENT';
    const eventOwner = isPresident ? { eventOwner: get(currentUser, 'objectId', '') } : {};
    const params = { offerEvent: true, joinedEvent: isPresident ? false : true, ...eventOwner };
    this.props.requestWaitingStudentOfferEvents(params);
    if (!isPresident) {
      this.props.getOfferEventsByStatus({ status: 'PENDING' });
      this.props.getOfferEventsByStatus({ status: 'ACCEPT' });
      this.props.getOfferEventsByStatus({ status: 'REJECT' });
      return;
    }
    this.props.getOfferEventListRequest({statuses: ['REJECTED_REQUEST', 'WAITTING_FOR_ANSWER', 'ACCEPTED_REQUEST', 'EXAMINING_CANDIDATE', 'REJECTED_CANDIDATE', 'APPOINTED']});
  }

  handleOpen = (value, name) => {
    let list = this.state.list;

    // eslint-disable-next-line
    list.map((item, index) => {
      if (item.name === name) list[index] = {...list[index], open: value};
    });

    this.setState({ list });
  };

  render() {
    const { offerEvents, seo } = this.props;
    const { list } = this.state;
    // const events = [waitingEvent, accpectedEvent, rejectedEvent];
    const { pendingOffers, acceptOffers, rejectOffers } = this.props;
    const acceptedRequestEvents = offerEvents.filter(event => [OFFER_EVENT_STATUS_KEYS.ACCEPTED_REQUEST, OFFER_EVENT_STATUS_KEYS.EXAMINING_CANDIDATE, OFFER_EVENT_STATUS_KEYS.REJECTED_CANDIDATE, OFFER_EVENT_STATUS_KEYS.APPOINTED].includes(event.offerEventStatus));

    const waitingForAnswerEvents = offerEvents.filter(event => event.offerEventStatus === OFFER_EVENT_STATUS_KEYS.WAITTING_FOR_ANSWER);
    const rejectedCandidateEvents = offerEvents.filter(event => event.offerEventStatus === OFFER_EVENT_STATUS_KEYS.REJECTED_REQUEST);

    return (
      <>
        <Helmet>
          <title>{seo.title}</title>
          <meta name="title" content={seo.title} />
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keyword} />
          <meta name="twitter:image" content={seo.image} />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
        </Helmet>

        <div className="waiting-list">
          <div id="event-list-detail-container">
            {
              list.map((item, index) => {
                let events = null;
                if (item.name === '保留中') {
                  events = waitingForAnswerEvents.length > 0 ? waitingForAnswerEvents : pendingOffers;
                } else if (item.name === '辞退') {
                  events = rejectedCandidateEvents.length > 0 ? rejectedCandidateEvents : rejectOffers;
                } else {
                  events = acceptedRequestEvents.length > 0
                    ? acceptedRequestEvents
                    : acceptOffers;
                }
                return (
                  <CollapseElement
                    key={index}
                    open={item.open}
                    name={item.name}
                    role={ get(this.props.currentUser, 'role', '')}
                    title={item.name}
                    events={events}
                    onOpen={this.handleOpen}
                  />
                );
              })
            }
          </div>
        </div>
      </>
    );
  }
}

WaitingStudentList.propTypes = {
  waitingEvent: PropTypes.array.isRequired,
  accpectedEvent: PropTypes.array.isRequired,
  rejectedEvent: PropTypes.array.isRequired,
  requestWaitingStudentOfferEvents: PropTypes.func.isRequired,
  resetOfferEvents: PropTypes.func.isRequired,
  history: PropTypes.any,
  seo: PropTypes.object,
  currentUser: PropTypes.object,
  getSeoDataByCode: PropTypes.func,
  hasMore: PropTypes.any,
  page: PropTypes.any,
  getOfferEventListRequest: PropTypes.func,
  offerEvents: PropTypes.array,
  requestOffer: PropTypes.array,
  acceptOffer: PropTypes.array,
  rejectOffer: PropTypes.array

};

const mapStateToProps = state => {
  return {
    seo: state.get('seo'),
    currentUser: state.get('account').get('data'),
    waitingEvent: state.get('waitingStudentEvents').get('waitingEvent'),
    accpectedEvent: state.get('waitingStudentEvents').get('accpectedEvent'),
    rejectedEvent: state.get('waitingStudentEvents').get('rejectedEvent'),
    offerEvents: state.get('studentManagement').get('offerEvents'),
    pendingOffers: state.get('eventHistory').get('pendingOffers'),
    acceptOffers: state.get('eventHistory').get('acceptOffers'),
    rejectOffers: state.get('eventHistory').get('rejectOffers')
  };
};

const mapDispatchToProps = dispatch => ({
  getSeoDataByCode: () => dispatch(getSeoDataByCodeRequest('waiting_student_list')),
  requestWaitingStudentOfferEvents: (more) => dispatch(requestWaitingStudentOfferEvents(more)),
  resetOfferEvents: () => dispatch(resetWaitingStudentOfferEventList()),
  getOfferEventListRequest: (params) =>
    dispatch(getOfferEventListRequest(params)),
  getOfferEventsByStatus: ({ status }) => dispatch(getOfferEventsByStatusRequest({ status }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(WaitingStudentList));
